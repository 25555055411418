@import '~antd/dist/antd.css';

html,
body {
  padding: 0;
  margin: 0;
  background: #fff;
  font-size: 18px;
}

html {
  --antd-wave-shadow-color: #FF9226!important;
  --scroll-bar: 0;
}

.ant-card-body {
  padding: 24px 16px 12px 16px;
}

.ant-tabs-tab:hover {
  color: #FF9226;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #333;
  font-weight: 400;
  text-shadow: 0 0 0.25px currentColor;
}

.ant-tabs-ink-bar {
  position: absolute;
  background: #FF9226;
  pointer-events: none;
}

.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper:hover, 
.ant-input-affix-wrapper-focused{
  border-color: #FF9226!important;
}

.ant-radio-checked .ant-radio-inner, .ant-radio-inner:hover {
  border-color: #FF9226!important;
}

.ant-radio-inner::after, .ant-radio-inner::after{
  background-color: #FF9226!important;
}

.ant-form-item-label > label{
  color: #696F79;
}

.ant-radio-wrapper{
  color : #696F79;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner, 
.ant-checkbox:hover .ant-checkbox-inner, 
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #FF9226!important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #FF9226!important;
  border-color: #FF9226!important;
}

.ant-slider-handle{
  border: solid 2px rgba(255, 146, 38, 0.7)!important;
}

.ant-slider-track{
  background-color: #FF9226!important;
}

.ant-btn:hover {
  color: #FF9226!important;
  background-color: #FFf!important;
  border: 1px solid #FF9226;
}

.ant-layout{
  background: rgb(229,229,229, 0.1);
  padding: 0!important;
}

.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
  height: 64px;
}

.ant-modal-content{
  border-radius: 16px!important;
}

.ant-modal-body{
  padding: 30px;
}

@media (max-width: 400px){
  .ant-modal-body{
    padding: 10px;
  }
}



html,body{
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif!important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Montserrat', sans-serif;
}


